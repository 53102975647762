.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h2 {
  font-weight: 500;
}
h1 {
  font-weight: 700;
}
.App-link {
  color: #61dafb;
}

/***********************************PAGINA FORM******************************/
.dashboard-container {
  margin: auto;
  margin-top: 8vh;
  max-width: 91%;
  background-color: white;
  /*box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
	background-color: white; */
  border: 1px solid lightgray;
  border-radius: 13px;
  padding: 0 30px;
  margin-bottom: 100px;
  min-height: 100vh;
  margin-left: max(7%, 100px);
}
.form-container {
  margin: auto;
  margin-top: 8vh;
  max-width: 95%;
  background-color: white;
  /*box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
	background-color: white; */
  border: 1px solid lightgray;
  border-radius: 13px;
  padding: 0 30px;
  margin-bottom: 100px;
  min-height: 100vh;
}
.min100 {
  min-height: 100vh;
}
.form-group {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.form-group input {
  font-weight: 300 !important;
}

.form-group label {
  color: rgb(88, 81, 81) !important;
  font-weight: bold !important;
  margin-bottom: 4px;
}

.form-control {
  width: 100%;
  font-size: 1rem !important ;
}
.form-control:focus {
  background-color: white;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.form-control-lg {
  border-radius: 0.3rem;
  min-width: 30vw;
}
.mantine-16ttirm {
  min-width: 150px;
}
.inline-item {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;
  justify-content: flex-start;
}
.inline-item a {
  width: 100% !important;
}
.gap20 {
  gap: 0 !important;
  width: 400px;
}
.gap10 {
  gap: 10px !important;
}
.gap5 {
  gap: 5px !important;
  font-size: 15px;
}
.mantine-Select-root {
  width: 100% !important;
}

@media screen and (max-width: 700px) {
  .grid {
    display: block;
    gap: 0;
    width: 100%;
  }
  .form-container {
    padding: 0 25px !important;
  }
}
@media screen and (min-width: 701px) {
  .grid {
    display: grid !important;
    grid-template-columns: auto auto auto;
    gap: 40px;
  }
}
.center {
  align-items: center;
}
.form {
  color: blue;
  cursor: pointer;
}
.mantine-1f0bp7j {
  border: 1px solid black !important;
}

.mantine-16ttirm {
  margin-bottom: 15px !important;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
  width: 100%;
  gap: 10px;
}
.repeat1 {
  grid-template-columns: repeat(1, 1fr) !important;
}
.repeat2 {
  grid-template-columns: repeat(2, 1fr) !important;
}
.repeat2File {
  grid-template-columns: repeat(2, 1fr) !important;
}
.mantine-DatePicker-root {
  width: 100%;
}
.repeat3 {
  grid-template-columns: repeat(3, 1fr) !important;
}
.repeat4 {
  grid-template-columns: repeat(4, 1fr) !important;
}
.repeat3v2 {
  grid-template-columns: repeat(3, 1fr) !important;
}
@media screen and (max-width: 1100px) {
  .repeat3v2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 750px) {
  .repeat3v2 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media screen and (max-width: 550px) {
  .repeat2File {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media screen and (max-width: 700px) {
  .repeat3 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 400px) {
  .repeat2 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .repeat3 {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 1300px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 !important;
  }
}
.repeat4 {
  grid-template-columns: repeat(4, 1fr) !important;
}
@media screen and (max-width: 800px) {
  .repeat4 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media screen and (max-width: 550px) {
  .repeat4 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.inlinegap20 {
  gap: 20px !important;
}
/*********************************PAGINA LOGIN*****************************************/

.labelFile {
  background-color: #1c6ea4;
  height: 50px !important;
  color: white !important;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
}
.log-container {
  position: relative;
  min-height: 100vh !important;
  width: 100% !important;
  z-index: 0;
}

/* .log-box {
  grid-area: im;
  background: url( ./components/sfondo.jpg ) center no-repeat;

  left: 0;
} */
.log-box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100% !important;
  position: absolute;
  z-index: 1;
}
.mw200 {
  width: 50%;
}

@media screen and (max-width: 550px) {
  .mw200 {
    width: 100% !important;
  }
}
.mw400 {
  max-width: 400px !important;
}
/*register*/
.mb30 {
  margin-bottom: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb0 {
  margin-bottom: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.mantine-1l5eptp:disabled {
  color: black !important;
  opacity: 1.6;
}
.mantine-8f3i9k:disabled {
  color: black !important;
  opacity: 1.6;
}
.mantine-8wnasg:disabled {
  color: black !important;
  opacity: 1.6;
}
.mantine-3e25cv:disabled {
  color: black !important;
  opacity: 1.6;
}
.mantine-zo7ioi:disabled {
  color: black !important;
  opacity: 1.6;
}
.mantine-1u2vbck {
  color: black !important;
  opacity: 1.6;
}
.mantine-1k57tdo:disabled {
  color: black !important;
  opacity: 1.6;
}
.login-form-container {
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  position: absolute;
  height: 100%;
  margin-top: 8vh;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);

  padding: 50px 50px;
  margin-bottom: 100px;
}
.register-box {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 20px 30px;
  margin-bottom: 30px;
}
.min {
  margin-top: 10vh;
  margin-bottom: 50px;
}

@media screen and (max-height: 800px) {
  .min {
    margin-top: 13vh;
  }
}

@media screen and (min-width: 650px) {
  .register-box {
    min-width: 600px;
    margin-top: 16vh;
  }
}
@media screen and (max-height: 650px) {
  .a .register-box {
    margin-top: 60vh !important;
  }
}

@media screen and (max-height: 800px) {
  .a.register-box {
    margin-top: 40vh;
  }
}

@media screen and (max-height: 530px) {
  .a .register-box {
    overflow-y: scroll;
    min-height: 90vh;
  }
}
#signup-strength-bar {
  background-color: #dcdcdc;
  height: 10px !important;
  position: relative;
  border-radius: 0 0 5px 5px;
  border-bottom-left-radius: 5px !important ;
}
.signup-strength {
  width: 25% !important;
  height: 10px !important;
  display: inline-block;
  position: relative;
  height: 100%;
  bottom: 8px !important;
  background-color: green;
}
#signup-strength-bar span {
  top: unset !important;
  right: 0 !important;
}
.firstSpan {
  border-bottom-left-radius: 5px !important;
}
.lastSpan {
  border-bottom-right-radius: 5px !important;
}
.login-form-container input {
  margin-bottom: 30px !important;
}
.login-form-container button {
  width: 500px;
}
.provass .mantine-PasswordInput-wrapper .mantine-1fft193 {
  border-radius: 0 !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}
.provass .mantine-PasswordInput-wrapper .mantine-fqc1l {
  border-radius: 0 !important;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

/***********************************NAVBAR**********************************/
.navbar-container {
  background: #333333;
  top: 0;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  z-index: 1000;
  left: 0px;
  transition: all 350ms ease-in;
  position: fixed;
  box-shadow: 0 0 11px rgb(0, 0, 0, 0.5);
}

@media screen and (max-height: 750px) {
  .log-container {
    height: 100vh !important;
  }
}

.nav-menu {
  display: flex !important;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
}

.nav-menu ul {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  margin-right: 20px !important;
  align-items: center;
}

.nav-menu ul li a {
  font-size: 18px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  cursor: pointer;
  display: grid;
  place-items: center;
  align-items: center;
  position: relative;
}

/* .nav-menu ul li a:hover {
	color: white;
	text-decoration: underline;
} */
.item {
  text-decoration: none;
  margin: 0 10px;
  display: flex;
}
.money {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: right;
  background: rgba(238, 180, 180, 0.3);
  border-radius: 15px;
  min-width: 100px !important;
  width: calc(100% - 10px);
}
.item h5 {
  width: 100%;
  text-align: right;
}

.figlio .money {
  min-width: 100% !important;
}
.figlio .item {
  margin: 0px;
}
.money-img {
  transform: translate(15%, 0);
}
.money-count {
  margin: 0 !important;
  padding: 0 !important;
  color: orange;
  font-size: 16px;
  line-height: 14px;
  height: 15px;
  transform: translate(7px, 0);
}

.nav-menu ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: white;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.nav-menu ul li a:hover::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

/* .nav-menu ul li:hover {
	background: rgb(0, 0, 0, 0.2);
} */

.sideBtn {
  color: white;
  cursor: pointer;
}

.navbar-container > div {
  margin-left: 1vh;
}
/***********************FOOTER***************************/

/*footer*/
.footer-c {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  background: black;
}
.menu li {
  font-size: 13px !important;
}
.footer-container {
  font-size: 10px;
  width: 30%;
  justify-content: left;
  padding: 30px;
}

.footer-link-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px;
  width: 100%;
  color: whitesmoke;
}

.footer-content {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.footer-content a {
  text-decoration: none;
  color: lightgray;
  font-weight: normal;
  font-size: 10px !important;
  width: 500px !important;
}
.footer-contentb {
  display: flex;
  flex-direction: column;
  width: 100px;
  gap: 0;
}
.footer-contentb a {
  text-decoration: none;
  color: lightgray;
  font-weight: normal;
  font-size: 10px !important;
}
.footer-content span {
  margin-bottom: 5px !important;
}
@media screen and (max-width: 1500px) {
  .footer-container {
    width: 80%;
  }
}

@media screen and (max-width: 900px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-link-container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
  .footer-logo {
    margin-bottom: 50px;
  }
  .a {
    text-align: center;
  }
  .b {
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-link-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer-logo {
    margin-bottom: 50px;
  }

  .a {
    text-align: center;
  }
  .b {
    text-align: center;
  }
}
/***********ADMIN DASHBOARD***********************************************/

.mantine-16ttirm {
  margin-bottom: 0 !important;
}
.pointer {
  cursor: pointer;
}
.space-between {
  justify-content: space-between !important;
}
.exclamation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}
.mt30 {
  margin-top: 30px;
}
.buttonConfermaContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}
.codiceEtico {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.informazioniAggiuntiveDipendente {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 20px;
  flex-wrap: wrap;
}
.maxW {
  max-width: 350px;
}
.maxW300 {
  max-width: 300px !important;
}
.grid-containerAttestati {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 10px;
  width: 100%;
  gap: 10px;
}
.itemAttestato {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  width: 100%;
}
.padding0 {
  padding: 0 !important;
}
.grid1 {
  grid-template-columns: repeat(1, 1fr) !important;
}
.margin0 {
  margin: 0 !important;
}
.w150 {
  width: 150px;
}
.singolaInfo {
  display: flex;
  justify-content: center;
  align-items: self-end;
  flex-direction: row;
}
.singleAttestato {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.containerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.overflowX {
  overflow-x: auto;
}
.inlineButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex {
  flex: 1;
  flex-wrap: wrap;
}
.w200 {
  width: 200px;
}
.mw450 {
  max-width: 450px;
}
.containerDownload {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.mr15 {
  margin-right: 15px;
}
.containerText {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 10px;
}
.footerTabella {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
  gap: 10px;
}
.inlineTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.mt20 {
  margin-top: 20px;
}
.containerAdd {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.center {
  align-items: center;
}
.inlineCheck {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  width: 450px;
}
.mb40 {
  margin-bottom: 40px;
}

/* Google Font Import - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  /* ===== Colors ===== */
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --primary-color: #695cfe;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}

body {
  min-height: 100vh;
  background-color: var(--body-color);
  transition: var(--tran-05);
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

body.dark {
  --body-color: #18191a;
  --sidebar-color: #242526;
  --primary-color: #3a3b3c;
  --primary-color-light: #3a3b3c;
  --toggle-color: #fff;
  --text-color: #ccc;
}

/* ===== Sidebar ===== */
.sidebar {
  position: fixed;
  height: fit-content !important;
  left: 0;
  margin-left: 10px;
  height: 100%;
  width: 225px;
  padding: 10px 13px;
  background: white;
  transition: var(--tran-05);
  z-index: 100;
  border-radius: 13px;
}
.sidebar.close {
  width: 77px;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
  max-width: 25px;
  border-radius: 6px;
}

.sidebar .icon {
  max-width: 25px;
  border-radius: 6px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
  transition: var(--tran-03);
}

.sidebar .text {
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}
.sidebar.close .text {
  opacity: 0;
}
/* =========================== */

.sidebar header {
  position: relative;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}
.sidebar header .logo-text {
  display: flex;
  flex-direction: column;
}
header .image-text .name {
  margin-top: 2px;
  font-size: 20px;
  font-weight: 600;
}

header .image-text .profession {
  font-size: 18px;
  margin-top: -2px;
  display: block;
}

.sidebar header .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar header .image img {
  width: 40px;
  border-radius: 6px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--primary-color);
  color: var(--sidebar-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-05);
}

body.dark .sidebar header .toggle {
  color: var(--text-color);
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar li.search-box {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar li.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-color-light);
  color: var(--text-color);
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  transition: var(--tran-05);
}
.sidebar li a {
  list-style: none;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 10px;
}

.sidebar li a:hover {
  background-color: #1c7ed6;
}
.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}
body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
  color: var(--text-color);
}

.sidebar .menu-bar {
  /* height: calc(100% - 55px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 60px;
}
.menu-bar::-webkit-scrollbar {
  display: none;
}
.sidebar .menu-bar .mode {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
  height: 50px;
  width: 60px;
}
.repeat6 {
  grid-template-columns: repeat(6, 1fr);
}
.mode .sun-moon i {
  position: absolute;
}
.mode .sun-moon i.sun {
  opacity: 0;
}
body.dark .mode .sun-moon i.sun {
  opacity: 1;
}
body.dark .mode .sun-moon i.moon {
  opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}
.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

body.dark .switch::before {
  left: 20px;
}

.home {
  position: absolute;
  top: 0;
  top: 0;
  left: 250px;
  height: 100vh;
  width: calc(100% - 250px);
  background-color: var(--body-color);
  transition: var(--tran-05);
}
.home .text {
  font-size: 30px;
  font-weight: 500;
  color: var(--text-color);
  padding: 12px 60px;
}

.sidebar.close ~ .home {
  left: 78px;
  height: 100vh;
  width: calc(100% - 78px);
}
body.dark .home .text {
  color: var(--text-color);
}
.mt8 {
  margin-top: 8vh;
}
.container_search {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_statistiche_certificazioni {
  width: 100%;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.35);
  padding: 25px;
}
.container_certificazione_selezionata {
  width: 100%;
  height: fit-content;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.nessun_appaltatore h5 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: lightgrey;
}
.mb20 {
  margin-bottom: 20px;
}
.title_dashboard {
  font-size: 30px;
  font-weight: 600;
}
.container_statistiche_appaltatori {
  width: 100%;
  height: fit-content;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.35);
  padding: 25px;
}
.container_statistiche {
  display: flex;
  flex-direction: column;
}
.container_statistiche_tempo_medio {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.35);
  padding: 25px;
}
.card_tempo_medio {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container_card_statistiche {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.container_tempo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.container_tempo h4 {
  margin: 0 !important;
  font-size: 17px !important;
  color: white;
}
.container_tempo h3 {
  margin: 0 !important;
  font-size: 15px !important;
  color: white;
}
.container_info_appaltatori {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mb50 {
  margin-bottom: 50px;
}
.container_informazioni_appaltatori {
  width: 100%;
  height: 100%;
}
.card_info_appaltatori {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 300px;
  background-color: #61dafb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.maiuscolo {
  text-transform: uppercase;
}
.link {
  color: rgb(5, 55, 72);
}
.title_statistiche {
  border-bottom: 1px solid lightgray;
}

.container_chart {
  height: 250px;
}

.colorBlue {
  background-color: #208aff;
}
.colorGreen {
  background-color: #1fd09d;
}
.colorPink {
  background-color: #eb59ac;
}
.color1 {
  background-color: #40376e;
}
.color2 {
  background-color: #531cb3;
}
.colorWhite {
  color: white !important;
}
.title_stabilimento {
  text-align: center;
}
.container_header_statistiche_stabilimento {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid white;
}
.container_statistica_grafico_torta {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  flex: 33%;
  height: 100%;
  max-width: 37%;
}

@media screen and (max-width: 900px) {
  .container_statistica_grafico_torta {
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    flex: 100% !important;
    height: 100%;
    max-width: 100% !important;
  }
  .container_statistica_grafico_torta_1 {
    flex: 100% !important;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 5px;
    flex-shrink: 0;
    height: 100%;
    max-width: 100% !important;
  }
}

.container_grafico_torta {
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.container_due_statistiche {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
.container_statistica_grafico_torta_1 {
  flex: 60.66%;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  flex-shrink: 0;
  height: 100%;
  max-width: 60.66%;
}
.container_header_statistiche {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.container_filtri_statistiche {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.colorGray {
  color: lightgray;
}
.taC {
  text-align: center;
}
.w100 {
  width: 100%;
}
.container_badge {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.container_testo {
  width: 100%;
}

.container_dropzone {
  margin-top: 10px;
}
.container_file_list {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.item_file {
  flex: 49%;
  background-color: lightgray;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 49%;
  border-radius: 3px;
  padding: 5px 15px;
}
.container_header_mail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inserimento_mail {
  width: 100%;
}
.container_appaltatori_list {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.row_appaltatore_list:hover {
  background-color: #f5f5f5;
}
.row_appaltatore_list {
  padding: 5px;
}
.container_mail_inserite {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.item_azione {
  border-radius: 5px;
  border: 1px solid lightgray;
  flex: 40%;
  gap: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.container_azioni {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}
.container_descrizione_azione {
  height: 50%;
  width: 100%;
}
.container_descrizione_azione h4 {
  text-align: start;
  color: rgb(152, 140, 140);
}
.colorMondorevive {
  background-color: #1c6ea4;
}
.pointerImportant {
  cursor: pointer !important;
}
.mr10 {
  margin-right: 10px;
}
.container_ricerca_e_button {
  display: flex;
  justify-content: left;
  width: 100%;
}
.container_mail {
  width: calc(100% - 95px);
}
.container_checkbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;
}
.mw300 {
  max-width: 300px;
}
.break-word {
  word-break: break-word !important;
}

.mt10 {
  margin-top: 10px;
}
.row2 {
  grid-template-columns: repeat(2, 1fr);
}
.ml20 {
  margin-left: 20px;
}
.container_unilav {
  display: flex;
  gap: 20px;
  justify-content: left;
  align-items: center;
}
.container_idoneita {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.mw700 {
  max-width: 700px;
}
.mantine-1au2im1:disabled {
  color: black;
}
.mantine-106b4wh:disabled {
  color: black;
}
.mantine-gz0qv2:disabled {
  color: black;
}
.mantine-jby1io:disabled {
  color: black;
}
.mantine-10q12oi:disabled {
  color: black;
}
.container_date_dati_generali {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.container_campi_mancanti {
  background-color: rgba(255, 0, 0, 0.872);
  border-radius: 5px;
  padding: 10px;

  padding-left: 30px;
}

.container_campi_mancanti li {
  color: white;
  font-weight: bold;
}
.container_campi_mancanti li::marker {
  color: white;
}
.container_campi_mancanti h2 {
  color: white;
}
.elenco_richiete_aggiuntive li {
  font-weight: bold;
  text-transform: capitalize;
}
.container_profilo_utente {
  padding: 10px;
}
.container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container_file_selezionati {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.container_fileButton {
  display: flex;
  align-items: center;
  color: white;
}
.container_nome {
  border-right: 1px solid white;
  padding: 7px;
  background-color: #1c7ed6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 36px;
  cursor: pointer;
}
.container_delete {
  padding: 7px;
  display: flex;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #1c7ed6;
  height: 36px;
  cursor: pointer;
}

.container_delete:hover {
  background-color: #1869b0;
}
.container_nome:hover {
  background-color: #1869b0;
}
.container_button_file_tabella {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.container_swatch_stati {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.container_singolo_swatch {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.container_button_indietro_verifica {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.mantine-TextInput-required {
  color: red;
}
.container_tempo_indeterminato {
  display: flex;
  align-items: center;
}
.container_nuovo_messaggio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.container_testo_aggiungo_informazioni_aggiuntive {
  padding: 15px;
  font-size: 1.2rem;
}
